import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { hydrate, render } from "react-dom";
import { BrowserRouter, MemoryRouter } from "react-router-dom";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <App />
    </BrowserRouter>
    , rootElement)
} else {
  render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
    , rootElement)
}

serviceWorker.unregister();
