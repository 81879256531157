import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../Supabase'

const Order = () => {
  const [first, setFirst] = useState('');
  const [second, setSecond] = useState('');
  const [third, setThird] = useState('');
  const [fourth, setFourth] = useState('');
  const [fifth, setFifth] = useState('');
  const [sixth, setSixth] = useState('');
  const [seventh, setSeventh] = useState('');
  const [eighth, setEighth] = useState('');

  const updatePlayer = (player, spots) => {
    console.log(player, spots);
    supabase
    .from('Picks24')
    .insert(spots.map(spot => {return {player: player, id: spot }}))
    .then((data)=> {

      console.log(data);
    })
    .error((err)=> {
      console.log(err);
    })
  }


  return (
    <div className='p-5 mt-10'>
      <div className='m-5'>
      <span className='mr-4'>1</span>
        <input className='border border-slate-300 mr-4' type='text' onChange={(e)=>setFirst(e.target.value)} value={first} />
        <button className='border border-slate-500 ml-5 p-3 w-32' 
        onClick={()=>{
          updatePlayer(first, [1, 16, 17, 32, 33, 48, 49, 64])
        }}>
        Lock In
        </button>
      </div>



      <div className='m-5'>
      <span className='mr-4'>2</span>
        <input className='border border-slate-300 mr-4' type='text' onChange={(e)=>setSecond(e.target.value)} value={second} />
        <button className='border border-slate-500 ml-5 p-3 w-32' 
        onClick={()=>{
          updatePlayer(second, [2, 15, 18, 31, 34, 47, 50, 63])
        }}>
        Lock In
        </button>
      </div>


      <div className='m-5'>
      <span className='mr-4'>3</span>
        <input className='border border-slate-300 mr-4' type='text' onChange={(e)=>setThird(e.target.value)} value={third} />
        <button className='border border-slate-500 ml-5 p-3 w-32' 
        onClick={()=>{
          updatePlayer(third, [3, 14, 19, 30, 35, 46, 51, 62])
        }}>
        Lock In
        </button>
      </div>


      <div className='m-5'>
      <span className='mr-4'>4</span>
        <input className='border border-slate-300 mr-4' type='text' onChange={(e)=>setFourth(e.target.value)} value={fourth} />
        <button className='border border-slate-500 ml-5 p-3 w-32' 
        onClick={()=>{
          updatePlayer(fourth, [4, 13, 20, 29, 36, 45, 52, 61])
        }}>
        Lock In
        </button>

      </div>


      <div className='m-5'>
      <span className='mr-4'>5</span>
        <input className='border border-slate-300 mr-4' type='text' onChange={(e)=>setFifth(e.target.value)} value={fifth} />
        <button className='border border-slate-500 ml-5 p-3 w-32' 
        onClick={()=>{
          updatePlayer(fifth, [5, 12, 21, 28, 37, 44, 53, 60])
        }}>
        Lock In
        </button>
      </div>


      <div className='m-5'>
      <span className='mr-4'>6</span>
        <input className='border border-slate-300 mr-4' type='text' onChange={(e)=>setSixth(e.target.value)} value={sixth} />
        <button className='border border-slate-500 ml-5 p-3 w-32' 
        onClick={()=>{
          updatePlayer(sixth, [6, 11, 22, 27, 38, 43, 54, 59])
        }}>
        Lock In
        </button>
      </div>


      <div className='m-5'>
      <span className='mr-4'>7</span>
        <input className='border border-slate-300 mr-4' type='text' onChange={(e)=>setSeventh(e.target.value)} value={seventh} />
        <button className='border border-slate-500 ml-5 p-3 w-32' 
        onClick={()=>{
          updatePlayer(seventh, [7, 10, 23, 26, 39, 42, 55, 58])
        }}>
        Lock In
        </button>
      </div>

      <div className='m-5'>
      <span className='mr-4'>8</span>
        <input className='border border-slate-300 mr-4' type='text' onChange={(e)=>setEighth(e.target.value)} value={eighth} />
        <button className='border border-slate-500 ml-5 p-3 w-32' 
        onClick={()=>{
          updatePlayer(eighth, [8, 9, 24, 25, 40, 41, 56, 57])
        }}>
        Lock In
        </button>
      </div>
    </div>
  );
};

export default Order;
