import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../Supabase'

const Draft = () => {

  const [picks, setPicks] = useState([]);
  const [search, setSearch] = useState('');
  const [searchPicks, setSearchPicks] = useState([]);
  const [teamsLeft, setTeamsLeft] = useState(false);


  const kevin = picks.filter((pick, i) => {
    return pick.status && (pick.player === 'Kevin')
  })

  const brandon = picks.filter((pick, i) => {
    return pick.status && (pick.player === 'Brandon')
  })

  const jaylen = picks.filter((pick, i) => {
    return pick.status && (pick.player === 'Jaylen')
  })

  const ty = picks.filter((pick, i) => {
    return pick.status && (pick.player === 'Ty')
  })

  const chuck = picks.filter((pick, i) => {
    return pick.status && (pick.player === 'Chuck')
  })

  const chandler = picks.filter((pick, i) => {
    return pick.status && (pick.player === 'Chandler')
  })

  const jake = picks.filter((pick, i) => {
    return pick.status && (pick.player === 'Jake')
  })

  const ethan = picks.filter((pick, i) => {
    return pick.status && (pick.player === 'Ethan')
  })

const renderTeamsLeft = () => {
  return (
    <div className='p-3'>
      <h5 className='text-center mb-5 font-bold'>Teams Remaining</h5>
      <span className='mb-3 font-medium flex items-center justify-center'>
        <span className='w-16 mr-6'>Kevin</span>
        <span className='font-bold text-lg border border-slate-200 px-2 text-blue-600'>{kevin.length}</span>
      </span>
      <span className='mb-3 font-medium flex items-center justify-center'>
        <span className='w-16 mr-6'>Brandon</span>
        <span className='font-bold text-lg border border-slate-200 px-2 text-blue-600'>{brandon.length}</span>
      </span>
      <span className='mb-3 font-medium flex items-center justify-center'>
        <span className='w-16 mr-6'>Ethan</span>
        <span className='font-bold text-lg border border-slate-200 px-2 text-blue-600'>{ethan.length}</span>
      </span>
      <span className='mb-3 font-medium flex items-center justify-center'>
        <span className='w-16 mr-6'>Jake</span>
        <span className='font-bold text-lg border border-slate-200 px-2 text-blue-600'>{jake.length}</span>
      </span>
      <span className='mb-3 font-medium flex items-center justify-center'>
        <span className='w-16 mr-6'>Chandler</span>
        <span className='font-bold text-lg border border-slate-200 px-2 text-blue-600'>{chandler.length}</span>
      </span>
      <span className='mb-3 font-medium flex items-center justify-center'>
        <span className='w-16 mr-6'>Chuck</span>
        <span className='font-bold text-lg border border-slate-200 px-2 text-blue-600'>{chuck.length}</span>
      </span>
      <span className='mb-3 font-medium flex items-center justify-center'>
        <span className='w-16 mr-6'>Jaylen</span>
        <span className='font-bold text-lg border border-slate-200 px-2 text-blue-600'>{jaylen.length}</span>
      </span>
      <span className='mb-3 font-medium flex items-center justify-center'>
        <span className='w-16 mr-6'>Ty</span>
        <span className='font-bold text-lg border border-slate-200 px-2 text-blue-600'>{ty.length}</span>
      </span>
    </div>

  )
}

const getPicks = () => {
  supabase
  .from('Picks24')
  .select('*')
  .order('id', { ascending: true })
  .then((data)=> {
    setPicks(data.data)
  })
  .catch((err) => {
    console.log(err);
  })
}

useEffect(()=> {
  supabase
  .from('Picks24')
  .on('*', payload => {
    console.log(payload);
    getPicks()
  })
  .subscribe()
}, [])

useEffect(()=>{
getPicks()
}, [])

const renderPicks = ()=> {
  let lastPick = 0;
  return picks.map((pick, i) => {
    return (
      <div className={`py-6 mx-3 px-3 flex border-b border-slate-300 ${pick.status ? '' : ' text-slate-200'}`}>
      <span className='pr-4 w-5 font-medium'>
        {pick.id}
      </span>
      <span className='px-4 w-20'>
        {pick.player}
      </span>
      <span className='pl-4 w-60 font-medium'>
        {pick.seed && (
          <>
          ({pick.seed}) {pick.team}
          </>

        )}
      </span>
      </div>
    )
  })
}

const renderSearchPicks = ()=> {
  return searchPicks.map((pick, i) => {
    return (
      <div className={`py-6 mx-3 px-3 flex border-b border-slate-300 ${pick.status ? '' : ' text-slate-200'}`}>
      <span className='pr-4 w-5 font-medium'>
        {pick.id}
      </span>
      <span className='px-4 w-20'>
        {pick.player}
      </span>
      <span className='pl-4 w-60 font-medium'>
        {pick.seed && (
          <>
          ({pick.seed}) {pick.team}
          </>

        )}
      </span>
      </div>
    )
  })
}
useEffect(()=> {
console.log(picks);
  const searchList = picks.filter((pick)=> {

    const isPlayerMatch = pick.player ? pick.player.toLowerCase().includes(search.toLowerCase()) : false;
    const isTeamMatch = pick.team ? pick.team.toLowerCase().includes(search.toLowerCase()) : false;
    return isPlayerMatch || isTeamMatch;
  })

  setSearchPicks(searchList)


  console.log(searchList);

}, [search])
  return (
    <div className='my-5 mx-auto max-w-3xl'>
    <div className='flex items-center justify-center w-full mb-5 mt-5'>
      <input className='border border-slate-300 p-3 rounded' placeholder="Search" type='text' onChange={(e)=>setSearch(e.target.value)} value={search}/>
      {search && (
        <span onClick={()=>setSearch('')} className='p-3 ml-3 rounded text-white font-bold cursor-pointer bg-red-600'>Cancel</span>
      )}
    </div>
    <div className='w-full mb-5 flex justify-center'>
      <button onClick={()=>setTeamsLeft(!teamsLeft)} className='bg-green-500 text-white rounded py-3 px-5'>Scoreboard</button>
    </div>
    {!search && !teamsLeft && (
      <>
      {picks.length > 0 && renderPicks()}
      </>
    )}

    {search && !teamsLeft && (
      <>
      {renderSearchPicks()}
      </>
    )}

    {teamsLeft && (
      <>
      {renderTeamsLeft()}
      </>
    )}
    </div>
  );
};

export default Draft;