import React, { useState, useEffect, useContext } from 'react';
import { supabase } from '../Supabase'

const Team = () => {

  const [picks, setPicks] = useState([]);
  const getPicks = () => {
    supabase
    .from('Picks24')
    .select('*')
    .order('id', { ascending: true })
    .then((data)=> {
      setPicks(data.data)
    })
    .catch((err) => {
      console.log(err);
    })
  }

useEffect(()=>{
  getPicks()

}, [])
useEffect(()=> {
  supabase
  .from('Picks24')
  .on('*', payload => {
    console.log(payload);
    getPicks()
  })
  .subscribe()
}, [])

const setStatus = (id, status) => {
  supabase
  .from('Picks24')
  .update({status: status })
  .eq('id', id)
  .then((data)=> {
    console.log(data);
  })
  .error((err)=> {
    console.log(err);
  })
}

const renderPicks = ()=> {
  return picks.map((pick, i) => {
    return (
      <div className='m-5 p-1 flex border-b border-slate-300 text-tiny max-w-[600px] mx-auto'>
      <span className='px-4 w-5 font-medium'>
        {pick.id}
      </span>
      <span className='px-4 w-16'>
        {pick.player}
      </span>
      <span className='px-4 w-32 font-medium'>
        {pick.seed && (
          <>
          ({pick.seed}) {pick.team}
          </>

        )}
      </span>
      <span className='flex w-24 justify-end'>
      <span onClick={()=>setStatus(pick.id, true)} className={`cursor-pointer p-2 border border-green-500 font-medium text-sm rounded ${pick.status ? 'text-white bg-green-500':'text-green-500 bg-white'} mr-3`}>
        In
      </span>
      <span onClick={()=>setStatus(pick.id, false)} className={`cursor-pointer p-2 border border-red-500 font-medium text-sm rounded ${!pick.status ? 'text-white bg-red-500':'text-red-500 bg-white'} mr-3`}>
        Out
      </span>
      </span>
      </div>
    )
  })
}

console.log(picks);
  return (
    <div className='p-2 mt-16'>
      <h2 className='text-center font-bold mb-5'>Set Status</h2>
      {picks.length > 0 && renderPicks()}
    </div>
  );
};

export default Team;
