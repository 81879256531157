import React, { useEffect, useState } from 'react';
import {
  Routes,
  Route,
  Link
} from "react-router-dom";
import { createClient } from '@supabase/supabase-js';
import { Provider } from 'react-supabase'
import ScrollToTop from './ScrollToTop'
import Nav from './Nav'
import Draft from './containers/Draft'
import Picks from './containers/Picks'
import Order from './containers/Order'
import Team from './containers/Team'
import { supabase } from './Supabase'

const App = () => {

  return (
    <div className='font-sans font-base font-light'>
    <div className='flex items-center justify-between mx-auto'>
    <a href='/' className='mx-auto block text-center my-2 font-bold'>Gus' Pool 2024 <br/>🏀🍀🏀🍀🏀🍀🏀</a>
    </div>
    <a href='/score' className='absolute top-2 right-2 block text-center m-3 font-bold'>✅/❌</a>
    <ScrollToTop>

    <Routes>

      <Route path="/" element={
        <>
          <Draft/>
        </>
      }>
      </Route>

      <Route path="/draft" element={
          <>
            <Draft/>
          </>
      }>
      </Route>

            <Route path="/picks" element={
          <>
            <Picks/>
          </>
      }>
      </Route>

      <Route path="/order" element={
          <>
            <Order/>
          </>
      }>
      </Route>
      <Route path="/score" element={
          <>
            <Team/>
          </>
      }>
      </Route>

      </Routes>

      </ScrollToTop>

    </div>
  );
};

export default App;
